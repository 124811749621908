.a,
.e,
.q,
.r {
    fill: #e1f2ff;
}
.ae,
.af,
.ag,
.ah,
.ai,
.aj,
.ak,
.am,
.ao,
.as,
.au,
.b,
.c,
.f,
.h,
.j,
.k,
.l,
.m,
.p,
.t,
.u,
.v,
.w,
.y {
    fill: none;
}
.b {
    stroke: #4992c7;
}
.ad,
.ae,
.af,
.ag,
.ah,
.ai,
.aj,
.ak,
.am,
.an,
.aq,
.at,
.au,
.b,
.c,
.o,
.p,
.q,
.r,
.s,
.t,
.y {
    stroke-width: 2px;
}
.aa,
.ab,
.ac,
.ad,
.ae,
.af,
.ag,
.ah,
.ai,
.aj,
.ak,
.am,
.an,
.ao,
.ap,
.as,
.au,
.b,
.c,
.d,
.e,
.f,
.g,
.h,
.i,
.j,
.k,
.l,
.m,
.n,
.o,
.p,
.q,
.r,
.s,
.t,
.u,
.v,
.w,
.x,
.y,
.z {
    fill-rule: evenodd;
}
.c {
    stroke: #9acded;
}
.ac,
.ad,
.aq,
.ar,
.at,
.d,
.g,
.n,
.o {
    fill: #fff;
}
.ac,
.ad,
.aq,
.at,
.d,
.h,
.p {
    stroke: #5e63b6;
}
.aa,
.d,
.f,
.h,
.j,
.k,
.l {
    stroke-width: 3px;
}
.ae,
.af,
.ag,
.ah,
.ai,
.aj,
.ak,
.ar,
.as,
.au,
.f,
.r,
.t {
    stroke: #acd3ef;
}
.i {
    fill: #acd3ef;
}
.j {
    stroke: #f1f4f9;
}
.k,
.w {
    stroke: #fff;
}
.aa,
.am,
.an,
.ao,
.l,
.m,
.n,
.o,
.z {
    stroke: #375d81;
}
.ar,
.as,
.m,
.n,
.z {
    stroke-width: 5px;
}
.q,
.s {
    stroke: #add2e9;
}
.s {
    fill: #9acded;
}
.v,
.y {
    stroke: #a3a0fb;
}
.x {
    fill: #a3a0fb;
}
.aa,
.an,
.z {
    fill: #375d81;
}
.ab {
    fill: #11324d;
}
.ac,
.ao {
    stroke-width: 4px;
}
.ae {
    stroke-dasharray: 5.708 5.708;
}
.af {
    stroke-dasharray: 4.106 3.079;
}
.ag {
    stroke-dasharray: 11.344 2.836;
}
.ah {
    stroke-dasharray: 12 5;
}
.ai {
    stroke-dasharray: 6.902 6.902;
}
.aj {
    stroke-dasharray: 3.843 2.882;
}
.ak {
    stroke-dasharray: 10.368 2.592;
}
.al {
    fill: #efeff6;
}
.ap {
    fill: #5e63b6;
}
.aq,
.ar,
.as,
.au {
    stroke-linecap: round;
}
